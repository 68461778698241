import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import styled from "styled-components"

const BookImage = styled.figure`
    position: relative;
    margin-bottom: 2em;
    @media ( max-width: 48em ) {
        width: 120vw;
        left: 50%;
        margin-left: -58vw;
    }
    @media ( min-width: 48em ) {
        margin-bottom: 3em;
    }
`

const BookContent = styled.div`
    h1 {
        font-size: var(--font-big);
        line-height: var(--font-big-lh);
        margin: 0;
    }
    a {
        border-bottom: 1px dotted;
        &:hover {
            border-bottom: 1px solid;
        }
    }
`
const BookPurchase = styled.div`
    display: grid;
    grid-template: auto / repeat(1, 1fr);
    grid-gap: 0.3em;
    width: 100%;
    div {
        padding: 1em 1.25em;
        background: #f6f6f6;
    }
    label {
        display: block;
        margin: 0 0 0.5em;
        text-transform: uppercase;
        font-size: 0.75em;
        font-weight: 700;
        letter-spacing: 0.1em;
        color: var(--color-highlight);
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline;
            &:not(:last-of-type)::after {
                content: ", "
            }
            a {
                border-bottom: 1px dotted;
                &:hover {
                    border-bottom: 1px solid;
                }
            }
        }
    }

    @media ( min-width: 48em ) {
        grid-template: auto / repeat(2, 1fr);
        div {
            &:nth-of-type(1) {
                grid-area: 1 / 1 / 2 / 3;
            }
            &:nth-of-type(2) {
                grid-area: 2 / 1 / 3 / 3;
            }
        }
    }
`



const Book = styled.div`
  position: relative;
  z-index: 1;
  padding: 3em 0;
  margin: 0 auto;
  max-width: 70em;
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -50vw;
      width: 100vw;
      background-color: var(--color-white);
    }
    @media ( min-width: 48em ) {
        padding-top: 4em;
        display: grid;
        grid-template: auto / repeat(2, 1fr);
        grid-gap: 1em 2em;
        ${BookImage} {
            grid-area: 1 / 1 / 2 / 3;
        }
        ${BookContent} {
            grid-area: 2 / 1 / 3 / 2;
        }
        ${BookPurchase} {
            grid-area: 2 / 2 / 3 / 3;
        }
    }
`

const dataPurchase = [
    {
        "country": "United States",
        "links": [
            {
                "name": "Amazon",
                "url": "https://www.amazon.com/gp/product/0451492943?tag=randohouseinc45903-20"
            },
            {
                "name": "Barnes & Noble",
                "url": "https://www.barnesandnoble.com/w/the-outlaw-ocean-ian-urbina/1130015657?ean=9780451492944&st=AFF&2sid=Random%20House%20Inc_8373827_NA&sourceId=AFFRandom%20House%20Inc"
            },
            {
                "name": "Indie Bound",
                "url": "https://www.indiebound.org/book/9780451492944?aff=penguinrandom"
            },
            {
                "name": "Apple Books",
                "url": "https://books.apple.com/us/book/id1447044973"
            },
            {
                "name": "Google Play",
                "url": "https://play.google.com/store/books/details/Ian_Urbina_The_Outlaw_Ocean?id=f39_DwAAQBAJ"
            },
            {
                "name": "Penguin Random House Audio Publishing",
                "url": "https://www.penguinrandomhouseaudio.com/book/538736/the-outlaw-ocean/"
            }
        ]
    },
    {
        "country": "United Kingdom",
        "links": [
            {
                "name": "Amazon",
                "url": "https://www.amazon.co.uk/Outlaw-Ocean-Survival-Untamed-Frontier/dp/1847925855/ref=sr_1_1?keywords=the+outlaw+ocean&qid=1556205715&s=gateway&sr=8-1"
            },
            {
                "name": "Foyles",
                "url": "https://www.foyles.co.uk/witem/biography/the-outlaw-ocean-crime-and-survival-in,ian-urbina-9781847925855"
            },
            {
                "name": "Waterstones",
                "url": "https://www.waterstones.com/book/the-outlaw-ocean/ian-urbina/9781847925855"
            },
            {
                "name": "Penguin Random House",
                "url": "https://www.penguin.co.uk/books/1118032/the-outlaw-ocean/9781847925855.html"
            },
            {
                "name": "Penguin Random House Audio Publishing",
                "url": "https://www.penguinrandomhouseaudio.com/book/538736/the-outlaw-ocean/"
            }
        ]
    },
    {
        "country": "Canada",
        "links": [
            {
                "name": "Indigo",
                "url": "https://www.chapters.indigo.ca/en-ca/books/the-outlaw-ocean-journeys-across/9780451492944-item.html?ikwid=the+outlaw+ocean&ikwsec=Home&ikwidx=2"
            },
            {
                "name": "Penguin Random House Audio Publishing",
                "url": "https://www.penguinrandomhouseaudio.com/book/538736/the-outlaw-ocean/"
            }
        ]
    },
    {
        "country": "Australia",
        "links": [
            {
                "name": "Amazon",
                "url": "https://www.amazon.com.au/Outlaw-Ocean-Survival-Untamed-Frontier-ebook/dp/B07MTY39G4/ref=sr_1_1?keywords=ian+urbina&qid=1568043633&s=books&sr=1-1"
            },
            {
                "name": "Penguin Random House Audio Publishing",
                "url": "https://www.penguinrandomhouseaudio.com/book/538736/the-outlaw-ocean/"
            }
        ]
    },
    {
        "country": "Germany",
        "links": [
            {
                "name": "Amazon",
                "url": "https://www.amazon.de/Outlaw-Ocean-Die-gesetzlose-See/dp/2496700865/ref=sr_1_2?__mk_de_DE=ÅMÅŽÕÑ&keywords=ian+urbina&qid=1568043569&s=gateway&sr=8-2"
            }
        ]
    },
    {
        "country": "The Netherlands",
        "links": [
            {
                "name": "Atlas Contact",
                "url": "https://www.atlascontact.nl/boek/de-jungle-op-zee/"
            }
        ]
    },
    {
        "country": "France",
        "links": [
            {
                "name": "Amazon",
                "url": "https://www.amazon.fr/Outlaw-Ocean-Survival-Untamed-Frontier/dp/1847925863/ref=sr_1_1?__mk_fr_FR=ÅMÅŽÕÑ&keywords=ian+urbina&qid=1568043497&s=gateway&sr=8-1"
            }
        ]
    }
]


export default ({ data }) => (
    <>
        <Helmet>
            <title>{data.book.title} | {data.site.siteMetadata.title}</title>
        </Helmet>
            <article>
                <Book>
                    <BookImage>
                        <Img fluid={data.books.childImageSharp.fluid} alt="" />
                    </BookImage>
                    <BookContent>
                        <h1>{data.book.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: data.book.content }} />
                    </BookContent>
                    <BookPurchase>
                        {dataPurchase.map((item, index) =>
                            <div>
                                <label>{item.country}</label>
                                <ul>
                                    {item.links.map((link) =>
                                        <li><a href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a></li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </BookPurchase>
                </Book>
            </article>
    </>
)

export const query = graphql`
    query {
        image: file(relativePath: { eq: "about.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid(maxHeight: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        book: directusBook {
            title
            content
        }
        books: file(relativePath: { eq: "UPDATE5covers.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
